import { get } from 'svelte/store';
import * as Sentry from '@sentry/browser';
import createPersistentStore from '$lib/utils/persistent-store';
/**
 * @type {import('svelte/store').Writable<import('./types').App.AppStore>} AppStore
 */
export const app = createPersistentStore('meta-app-store', {
	meta: {
		isIosViewer: '',
		viewerPostalCode: '',
		viewerLongitude: '',
		viewerLatitude: '',
		viewerAsn: '',
		viewerMetroCode: '',
		viewerCountryRegion: '',
		isAndroidViewer: '',
		inApp: false,
		viewerCity: '',
		viewerCountryName: '',
		viewerTls: '',
		forwardedProto: '',
		viewerCountryRegionName: '',
		viewerCountry: 'US',
		appVersion: '',
		viewerAddress: '',
		isDesktopViewer: '',
		isMobileViewer: '',
		viewerHttpVersion: '',
		viewerTimeZone: '',
		isSmarttvViewer: '',
		isTabletViewer: '',
		isAndroidApp: false,
		isSmartTvApp: false,
		isAmazonApp: false,
		isIosApp: false,
		isSamsungApp: false,
		isMac: false,
		isWindows: false,
		isLinkFromApp: false,
		isAmazonViewer: false,
		localeCode: '',
    experimentInfo: ''
	},
	global: {
		analyticsCookiesEnabled: true,
		gdprVisible: false,
		loading: false,
		loadingDarkMode: false,
		targetingCookiesEnabled: true
	}
});

/**
 * Sets the loading state to the specified value.
 * @param {boolean} value - The value to set the loading state to.
 * @param {boolean} loadingDarkMode - The value to set the loading state to.
 * @returns {void}
 */
export const setLoading = (value, loadingDarkMode) => {
	app.set({ ...get(app), global: { ...get(app).global, loading: value, loadingDarkMode } });
	Sentry.addBreadcrumb({
		category: 'ui-show',
		message: `Loading: ${value} - Dark Mode: ${loadingDarkMode}`,
		level: 'info'
	});
};

/**
 * @param {{ [key: string]: string | boolean | null; }} metaValues - new meta values
 * @returns {void}
 */
export const setMetaStore = (metaValues) => {
	app.update((current) => ({
		...current,
		meta: {
			...current.meta,
			...metaValues
		}
	}));
	Sentry.setTag('appVersion', get(app).meta.appVersion);
	Sentry.setTag('inApp', get(app).meta.inApp);
	Sentry.setTag('isIosApp', get(app).meta.isIosApp);
	Sentry.setTag('isAmazonApp', get(app).meta.isAmazonApp);
	Sentry.setTag('isAndroidApp', get(app).meta.isAmazonApp);
	Sentry.setTag('isWindows', get(app).meta.isIosApp);
	Sentry.setTag('isSamsungApp', get(app).meta.isAmazonApp);
	Sentry.setTag('isMac', get(app).meta.isAmazonApp);
	Sentry.setContext('meta', get(app).meta);
};

/**
 * @param {{ [key: string]: string | boolean | null; }} globalValues - new global values
 * @returns {void}
 */
export const setGlobalStore = (globalValues) => {
	app.set({ ...get(app), global: { ...get(app).global, ...globalValues } });
};
