import { writable } from 'svelte/store';

/**
 * Creates a Svelte store that syncs its value to localStorage, if available,
 * otherwise it falls back to in-memory storage.
 * @template T
 * @param {string} key - The localStorage key to use for storing the data.
 * @param {T} initialValue - Initial value of the store if localStorage is empty or unavailable.
 * @returns {import('svelte/store').Writable<T>} A Svelte writable store of type T
 */
function createPersistentStore(key, initialValue) {
	const isLocalStorageAvailable = typeof localStorage !== 'undefined';

	let data = initialValue;
	if (isLocalStorageAvailable) {
		const storedValue = localStorage.getItem(key);
		if (storedValue) {
			data = JSON.parse(storedValue);
		}
	}

	const store = writable(data);

	store.subscribe((value) => {
		if (isLocalStorageAvailable) {
			localStorage.setItem(key, JSON.stringify(value));
		}
		// Optionally, handle the fallback storage update here if needed
	});

	return store;
}

export default createPersistentStore;
